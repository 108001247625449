import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

interface SeoProps {
  description?: string;
  title: string;
  image?: string;
  children?: React.ReactNode;
  keywords?: string[];
  meta?: any[];
}

const Seo: React.FC<SeoProps> = ({ description, title, image, children, keywords }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );
  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <React.Fragment>
      <title>{`${title} / ${defaultTitle}`}</title>
      <meta name="apple-itunes-app" content="app-id=1535869072" />
      <meta name="title" content={`${title} / ${defaultTitle}`} />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={keywords?.join(', ') || 'pet, book, wellness, health, grooming, booking, services, daycare'} />
      <meta property="image" content={image} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={image} />
      <meta charSet="utf-8" />
      <meta httpEquiv="refresh" content="86400" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      {children}
    </React.Fragment>
  );
};
Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
};

export default Seo;
