import { Link } from 'gatsby';
import React from 'react';
import CollarLogo from '../../images/collarLogo.svg';
import Colors from '../../utils/Colors';

export function BlogFooter() {
  return (
    <Link
      to="https://collar.pet/download"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        textAlign: 'center',
        marginTop: 30
      }}
    >
      <CollarLogo />
      <span style={{ color: Colors.primaryGray, fontSize: 12, fontFamily: `Manrope` }}>One App. All your pet needs.</span>
    </Link>
  );
}
