import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import Seo from '../../components/Seo/Seo';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BlogFooter } from '../../components/BlogFooter/BlogFooter';

function Blog({ data }) {
  return (
    <Layout>
      <h1>Collar Blog</h1>
      <ul
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
          gridGap: 10
        }}
      >
        {data.blog.nodes.map(post => {
          const featuredImg = getImage(post.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData);
          return (
            <li key={post.parent.name}>
              <Link
                to={`/blog/${post.parent.name}`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: 400,
                  maxHeight: 400
                }}
              >
                <GatsbyImage
                  image={featuredImg}
                  style={{
                    marginBottom: 10,
                    borderRadius: 10
                  }}
                  alt={post.frontmatter.title}
                />
                <span>{post.frontmatter.title}</span>
              </Link>
            </li>
          );
        })}
      </ul>
      {GlobalStyle}
      <BlogFooter />
    </Layout>
  );
}

export default Blog;

export const Head = ({ data }) => (
  <Seo title={'Collar Blog'} description={'Pet Care & Health Blog, Tips & Tricks, Grooming, Training, and more'} keywords={['pet', 'care', 'health', 'blog', 'tips', 'tricks', 'grooming']} />
);

export const query = graphql`
  {
    blog: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/blog/" } }, sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        html
        frontmatter {
          title
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
    site: site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export const GlobalStyle = (
  <style jsx="true">{`
    body {
      background-color: #f5f5f5;
    }
    main {
      font-family: 'Helvetica Neue', sans-serif;
      margin: 2em;
      margin-top: 0;
      background-color: #f5f5f5;
      padding: 30px;
      overflow: scroll;
      height: 100vh;
      max-width: 1200px;
      margin: auto;
    }
    h1,
    h2,
    h3 {
      color: #333;
      margin-bottom: 0.5em;
    }
    p {
      color: #555;
      line-height: 1.2;
      margin-bottom: 1.6em;
    }
    li {
      color: #555;
      line-height: 1.2;
      margin-bottom: 1.6em;
    }
    a {
      color: var(--primary);
      text-decoration: none;
    }
    a:visited {
      color: var(--primary);
    }
    a:hover {
      text-decoration: underline;
    }
    @media (max-width: 600px) {
      main {
        margin: 0;
        padding: 1em;
        margin-bottom: 2em;
        padding-bottom: 2em;
      }
    }
  `}</style>
);
